// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-datuverndarkunning-js": () => import("./../../../src/pages/datuverndarkunning.js" /* webpackChunkName: "component---src-pages-datuverndarkunning-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-presentation-js": () => import("./../../../src/pages/presentation.js" /* webpackChunkName: "component---src-pages-presentation-js" */),
  "component---src-pages-samband-js": () => import("./../../../src/pages/samband.js" /* webpackChunkName: "component---src-pages-samband-js" */),
  "component---src-pages-um-okkum-js": () => import("./../../../src/pages/um-okkum.js" /* webpackChunkName: "component---src-pages-um-okkum-js" */),
  "component---src-pages-video-azenzus-js": () => import("./../../../src/pages/video/azenzus.js" /* webpackChunkName: "component---src-pages-video-azenzus-js" */),
  "component---src-pages-video-fit-js": () => import("./../../../src/pages/video/fit.js" /* webpackChunkName: "component---src-pages-video-fit-js" */),
  "component---src-pages-video-hygg-okkara-animationir-js": () => import("./../../../src/pages/video/hygg-okkara-animationir.js" /* webpackChunkName: "component---src-pages-video-hygg-okkara-animationir-js" */),
  "component---src-pages-video-leif-mohr-js": () => import("./../../../src/pages/video/leif-mohr.js" /* webpackChunkName: "component---src-pages-video-leif-mohr-js" */),
  "component---src-pages-video-raska-js": () => import("./../../../src/pages/video/raska.js" /* webpackChunkName: "component---src-pages-video-raska-js" */),
  "component---src-pages-video-til-lukku-kristian-js": () => import("./../../../src/pages/video/til-lukku-kristian.js" /* webpackChunkName: "component---src-pages-video-til-lukku-kristian-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

